import gql from 'graphql-tag'
// import clientFragment from './../../client/graphql/ClientFrangment'
import TestFragment from "./TestFragment"
export default gql`{
  id
  available
  client {
    id
    name
  }
  stages {
    name
    analyze
    startDate
    startTime
    endDate
    endTime
    tests{
      ...${TestFragment}
    }
  }
  description
  finalDate
  formation {
    course
    level
    status
    institution
    year
  }
  languages {
    name
    level
  }
  locale
  category
  place
  private
  professionalExperience {
    description
    yearsExperience
  }
  publicationDate
  status
  title
  type
}`