export default {
  data: () => ({
    confirm: false,
    dialog: false,
    error: undefined,
    isLoading: false,
    removeId: undefined,
    show: false,
    showError: false,
    showSuccess: false,
    success: undefined,
  }),
}

export const ADD_DIALOG = {
  data: () => ({
    confirm: false,
    error: undefined,
    isLoading: false,
    removeId: undefined,
    showError: false,
    showSuccess: false,
    success: undefined
  }),
}