import gql from "graphql-tag";

export default gql`{
  id
  available
  description 
  descriptionIntl {
    pt
    en
  }
  finalDate
  formation {
    course
    level
    status
    institution
    year
    weight
  }
  languages {
    name
    level
    weight
  }
  documents {
    name
    rule
  }
  areaInterest
  weight
  locale
  category
  place
  private
  professionalExperience {
    description
    yearsExperience
    sector
    weight
  }
  publicationDate
  status
  title
  jobTitle {
    pt
    en
  }
  type
  views
  stages {
    name
    analyze
    startDate
    startTime
    endDate
    endTime
  }
}`;
