<template>
  <v-hover v-slot:default="{ hover }" open-delay="200">
    <v-card
      :elevation="hover && !candidate.blocked ? 16 : 4"
      max-width="480"
      max-height="480"
      class="pa-3"
    >
      <div class="d-flex flex-row justify-space-between">
        <!-- <div>
          
        </div> -->
        <!-- <pre>
          .{{candidate.fullName}}
        </pre> -->
        <div>
          <v-icon v-if="candidate.blocked" color="primary" class="mb-6">
            lock
          </v-icon>
          <v-checkbox
            v-else
            v-model="checkbox"
            color="primary"
            @change="$emit('checked', candidate.id)"
            style="margin-top: -1%"
          ></v-checkbox>
        </div>
        <!-- <pre>{{match.split(",")[0]>50}}</pre> -->
        <div class="" v-if="match.split(',')[0] >= 50">
          <v-row class="mt-1">
            <v-chip color="primary" label  small><span class="black--text ">{{match.split(',')[0]>100?100:match}}% {{ $t("match") }}</span></v-chip
            >
          </v-row>

          <!-- <v-badge  overlap color="primary" light  tile :content="`${match.split(',')[0]>100?100:match}% Match`"></v-badge> -->
        </div>
      </div>
      <v-card flat @click="go(candidate.id)">
        <v-list-item>
          <v-list-item-avatar
            class="mx-auto py-0"
            color="grey"
            size="58"
            style="margin-top: -5%"
          >
            <img
              :src="
                candidate.photo
                  ? `${apiUrl}/images/candidate/${candidate.photo}`
                  : `/avatar.png`
              "
              alt=""
            />
          </v-list-item-avatar>
        </v-list-item>

        <div style="height: 150px">
          <v-list-item>
            <v-list-item-content>
              <v-list-item-subtitle class="text-center black--text pb-2">{{
                `${candidate.name} ${candidate.lastName}`
              }}</v-list-item-subtitle>
              <v-list-item-title class="title pb-2 text-center black--text">
                {{ candidate.profession }}
              </v-list-item-title>
              <v-list-item-subtitle
                class="subtitle-2 text-center pb-2 black--text"
              >
                {{ days(candidate.professionalExperience) }}
                {{ $t("years_of_experience") }}
              </v-list-item-subtitle>
              <div class="justify-center text-center">
                <v-rating
                  :value="rating"
                  color="yellow darken-3"
                  background-color="grey darken-1"
                  empty-icon="$ratingFull"
                  half-increments
                  hover
                  small
                  dense
                  readonly
                ></v-rating>
              </div>
            </v-list-item-content>
          </v-list-item>
          <!-- <pre>{{currentUser}}</pre> -->
        </div>
      </v-card>
    </v-card>
  </v-hover>
</template>

<script>
import { API_URL } from "@/api";
import yearsMixins from "@/mixins/years";
import { ADD_VIEW } from "../modules/vacancy/graphql/Mutation.resolver";
import { mapGetters } from "vuex";
export default {
  name: "CandidateCard",
  props: {
    candidate: Object,
    database: Boolean,
    filter: Object,
    from: String,
    selectAll: Boolean,
    vacancyId: String,
    match: String,
  },
  mixins: [yearsMixins],
  data: () => ({
    apiUrl: API_URL,
    checkbox: false,
    selectedCandidates: [],
  }),
  watch: {
    selectAll: function (val) {
      this.checkbox = val;
    },
  },
  created() {
    this.selectedCandidates = this.selected;
  },
  computed: {
    rating() {
      return (
        this.candidate.ratings.reduce((accumulator, item) => {
          return accumulator + item.value;
        }, 0) / this.candidate.ratings.length
      );
    },
    ...mapGetters({
      currentUser: "auth/getCurrentUser",
    }),
  },
  methods: {
    go(candidateId) {
      if (this.candidate.blocked) {
        // eslint-disable-next-line no-undef
        Fire.$emit("packageDialog", 1);
      } else {
        this.addView(candidateId);
        this.$router.push({
          path: `/dashboard/entity/${this.from}/profile/${candidateId}`,
          query: {
            filter: this.filter,
            from: this.from,
            vacancyId: this.vacancyId,
          },
        });
      }
    },
    async addView(candidateId) {
      await this.$apollo
        .mutate({
          mutation: ADD_VIEW,
          variables: {
            candidateId: candidateId,
            entity: this.currentUser.entity.id,
          },
        })
        .then((res) => {
          if (res) {
            console.log(res);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
</script>
