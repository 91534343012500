import gql from 'graphql-tag'

export default gql `{
  name
  flag
  messages{
    body
    date
    time
    read
    flag_to
    to{
      id
      name
      photo
      type
    }
    author {
      id
      name
      photo
      type
    }
  }
}`