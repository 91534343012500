export default {
  methods: {
    days (professionalExperience) {
      let sum = 0;
      let menorData = new Date('9999-12-31');
      let maiorData = new Date('0001-01-01');
      if (professionalExperience) {
        professionalExperience.map((exp,) => {
          if (exp.startDate) {
            const dataAtual = new Date(exp.startDate);
            if (dataAtual < menorData) {
              menorData = dataAtual;
            }
            sum += this.sumeExp(exp);
          }
          if (exp.endDate) {
            const dataAtual = new Date(exp.endDate);
            if (dataAtual > maiorData) {
              maiorData = dataAtual;
            }
          }
          if (exp.still_working) {
            maiorData = new Date()
          }
        });
        const diferencaEmMilissegundos = maiorData - menorData;
        const milissegundosPorAno = 1000 * 60 * 60 * 24 * 365.25; 
        const diferencaEmAnos = diferencaEmMilissegundos / milissegundosPorAno;
        return diferencaEmAnos <0? 0: parseInt(diferencaEmAnos)
      }
      return sum;
    },
    sumeExp ({ startDate, endDate , still_working }) {
      const dateIni = new Date(startDate);
      const dateFinal = endDate !== null ? still_working === true ? new Date(): new Date(endDate) : new Date();
      const differenceInTime = dateFinal.getTime() - dateIni.getTime();
      const differenceInDays = differenceInTime / (1000 * 3600 * 24);

      return differenceInDays;
    }
  }
}