import gql from 'graphql-tag'
import candidateFragment from './../../candidate/graphql/CandidateFragment'

export const CANDIDATE_CANDIDACY_SUBSCRIPTION = gql`
  subscription SandidateCandidacySubscription {
    candidateCandidacy {
      candidacy {
        appliedFor
        candidate {
          ...${candidateFragment}
        }
        favorite
        status
      }
      vacancyId
    }
  }
`

export const SOLVED_TEST_SUBSCRIPTION = gql`
  subscription SolvedTestSubscription {
    solvedTest {
      id
      candidate
      date
      description
      image
      questions {
        question
        objective
        implication
        alternatives {
          answer
          response
          value
        }
        totalCorrect
        percentage
        picture
      }
      result
      status
      time
      title
      type
      vacancy {
        id
      }
    }
  }
`
 