import gql from 'graphql-tag'

export default gql `{
  id
  areas
  category
  creatorType
  description
  difficultyLevel
  duraction
  entity
  image
  openResponse
  positions
  price
  pub
  questions {
      question
      objective
      implication
      file
      openQuestion
      alternatives {
        answer
        value
        textResponse
      }
      totalCorrect
      percentage
      picture
    }
  status
  isDraft
  title
  type
  user {
    id
    name
    office
    photo
  }
}`