<template>
  <v-menu
    v-model="menu"
    bottom
    right
    transition="scale-transition"
    origin="top left"
  >
    <template v-slot:activator="{ on }">
      <v-chip
        pill
        v-on="on"
      >
        <v-avatar left>
          <v-img :src="candidate.photo
                ? `${apiUrl}/images/candidate/${candidate.photo}`
                : `/avatar.png`">
          </v-img>
        </v-avatar>
        {{ candidate.name.split(' ')[0] }} {{ candidate.lastName }}
      </v-chip>
    </template>
    <v-card width="300">
      <v-list dark>
        <v-list-item>
          <v-list-item-avatar>
            <v-img :src="candidate.photo
                ? `${apiUrl}/images/candidate/${candidate.photo}`
                : `/avatar.png`">
            </v-img>
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title>{{ candidate.name.split(' ')[0] }} {{ candidate.lastName }}</v-list-item-title>
            <v-list-item-subtitle>{{ candidate.email }}</v-list-item-subtitle>
          </v-list-item-content>
          <v-list-item-action>
            <v-btn
              icon
              @click="menu = false"
            >
              <v-icon>mdi-close-circle</v-icon>
            </v-btn>
          </v-list-item-action>
        </v-list-item>
      </v-list>
      <v-list>
        <v-list-item @click="() => {}">
          <v-list-item-action>
            <v-icon>mdi-briefcase</v-icon>
          </v-list-item-action>
          <v-list-item-subtitle>{{ candidate.email }}</v-list-item-subtitle>
        </v-list-item>
      </v-list>
    </v-card>
  </v-menu>
</template>

<script>
import { API_URL } from '@/api'
export default {
  name: 'CandidateChip',
  props: {
    candidate: Object
  },
  data: () => ({
    apiUrl: API_URL,
    menu: false,
  }),
}
</script>