import gql from "graphql-tag";
import candidateFragment from "./CandidateFragment.gql";
import userFragment from "./UserFragment.gql";
import vacancyFragment from "./VacancyFragment";

export const GET_CANDIDATE_BY_ID_QUERY = gql`
  query GetCandidateById($id: ID!) {
    candidate: getCandidateById(id: $id) {
      ...${candidateFragment}
    }
  }
`;

export const USER_QUERY = gql`
  query User {
    user {
      ...${userFragment}
    }
  }
`;
export const CANDIDATE_SKILL = gql`
  query getCandidatesSkill {
    Skills: getCandidatesSkill
  }
`;
export const SHARED_VACANCIES = gql`
  query getSharedVacacny($id:ID!) {
    getSharedVacacny(id :$id){
      ...${vacancyFragment}
    }
  }
`;

export const GET_TRAINING_AREAS = gql`
  query getTrainngAreas {
    getTrainingAreas {
      id
      text {
        en
        pt
      }
      key
    }
  }
`;
