export default class Partner {
  constructor (id, areas, category, description, difficultyLevel, duraction, entity, 
    image, openResponse, positions, price, pub, questions, status
  ) {
    this.id = id
    this.areas = areas
    this.category = category
    this.description = description
    this.difficultyLevel = difficultyLevel
    this.duraction = duraction
    this.entity = entity
    this.image = image
    this.openResponse = openResponse
    this.positions = positions
    this.price = price
    this.pub = pub
    this.questions = questions
    this.status = status
  }
}