import gql from 'graphql-tag'

export default gql`{
  id
  address
  age
  areasInterest
  availability
  basicSalary
  belongsTo
  certificates
  city
  dateBirth
  email
  entities
  belongsToMany
  province
  formation {
    course
    level
    status
    institution
    startDate
    endDate
    year
  }
  fullName
  genre
  idealSalary
  languages {
    name
    level
  }
  lastName
  media {
    facebook
    linkedin
    twitter
    website
  }
  name
  nationality
  newCreated
  notes {
    id
    photo
    name
    text
    time
    user
  }
  otherSkills
  photo
  presentation
  profession
  professionalExperience {
    entity
    description
    office
    startDate
    endDate
    sector
    still_working
  }
  ratings {
    entity
    value
  }
  references {
    fullName
    office
    telephone
  }
  status
  telephone
  telephone2
}`