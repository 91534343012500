export default {
  methods: {
    replaceSpecialChars (str) {
      str = str.replace(/[ÀÁÂÃÄÅ]/, "A");
      str = str.replace(/[àáâãäå]/, "a");
      str = str.replace(/[ÈÉÊË]/, "E");
      str = str.replace(/[èéêëēĕėęěȅȇȩ]/, "e");
      str = str.replace(/[ÌÍÎÏĨĪĬĮİĺľ]/, "I");
      str = str.replace(/[ìíîïĩīĭįǐȉȋ]/, "i");
      str = str.replace(/[ÒÓÔÕÖŌŎŐƠȌȎǑȪȬȮȰ]/, "O");
      str = str.replace(/[òóôõöōŏőǒ]/, "o");
      str = str.replace(/[ÙÚÛÜŨŪŬŮǓǕǗǙǛȔȖ]/, "U");
      str = str.replace(/[ùúûüũūůűųǔǖǘǚǜȕȗ]/, "u");
      str = str.replace(/[Ç]/, "C");
      str = str.replace(/[ç]/, "c");

      // o resto

      return str
        .replace(/[^a-z0-9]/gi, "")
        .trim()
        .toLowerCase();
    }
  }
}