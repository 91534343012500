import gql from 'graphql-tag'
import candidateFragment from './CandidateFragment'

export const GET_CANDIDATES_ENTITY_QUERY = gql `
  query GetCandidatesEntity($filterCandidate: FilterCandidate) {
    candidates: getCandidatesEntity(filterCandidate: $filterCandidate) {
      candidates {
        ...${candidateFragment}
      }
      totalDocs
      totalPages
    }
  }
`

export const GET_CANDIDATES_ENTITY_SEARCH = gql `
  query GetCandidatesEntitySearch($name: String, $limit: Int) {
    candidates: getCandidatesEntitySearch(name: $name, limit:$limit) {
      candidates {
        ...${candidateFragment}
      }
      totalDocs
      totalPages
    }
  }
`


export const GET_UNSOLICITED_CANDIDATES_ENTITY_QUERY = gql `
  query GetUnsolicitedCandidancy($filterCandidate: FilterCandidate) {
    candidates: getUnsolicitedCandidancy(filterCandidate: $filterCandidate) {
      candidates {
        ...${candidateFragment}
      }
      totalDocs
      totalPages
    }
  }
`

export const GET_CANDIDATE_BY_ID_QUERY = gql `
  query GetCandidateById($id: ID!) {
    candidate: getCandidateById(id: $id) {
      ...${candidateFragment}
    }
  }
`
export const GET_CANDIDATE_NOTES_QUERY = gql `
  query getCandidateNotes($candidateId: ID!) {
    notes: getCandidateNotes(candidateId: $candidateId) {
      name
      text
      time
    }
  }
`

export const GET_CANDIDATE_LISTS_QUERY = gql `
  query GetCandidateLists {
    lists: getCandidateLists {
      id
      candidates
      name
    }
  }
`

export const GET_CLIENTS_ENTITY_QUERY = gql `
  query GetClientsEntity {
    clients: getClientsEntity {
      id
      name
    }
  }
`

export const GET_PUBLISHED_VACANCIES_QUERY = gql `
  query GetPublishedVacancies {
    vacancies: getPublishedVacancies {
      id
      title
    }
  }
`
export const GET_UNSOLICITED_CANDIDATE = gql `
  query getUnsolicitedCandidates {
    Length : getUnsolicitedCandidates
  }
`
export const GET_TOTAL_CANDIDATE = gql `
  query totalCandidatesByEntity {
    Length : totalCandidatesByEntity 
  }
`