export default {
  methods: {
    accessLevel (currentUser, permission) {
      if (currentUser.role.name === 'Administrator' || currentUser.role.name === 'administrator') {
        return true
      }
      const index = currentUser.role.permissions.findIndex(p => p.name == permission)
      return index > -1
    }
  }
}